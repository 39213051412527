import { createSelector } from '@reduxjs/toolkit'

import {
	ComponentName,
	COMPONENT_NAME_LIST,
	RootState,
	UIState,
} from '../../model/model'
import { InstrumentInRoom } from '../../model/instruments'
import { selectIsDoctor } from '../users/selectors'
import { ExamStep } from '../../model/exam'

export const selectUI = (state: RootState): UIState => state.ui

export const selectDoctorExamPageActiveStep = (state: RootState) =>
	state.ui.doctorExamPageActiveStep

export const selectDoctorExamPageActivePanel = createSelector<
	[(state: RootState) => UIState],
	InstrumentInRoom | string
>(selectUI, ({ doctorExamPageActivePanel }) => doctorExamPageActivePanel || '')

export const selectPageEditingStatus =
	(component: ComponentName) => (state: RootState) =>
		!!state.ui.isComponentEditing[component]

export const selectPageEditingStatusAggregated = () => (state: RootState) => {
	return (
		// NB: in order page -> result form -> test form is always in edit mode
		state.ui.doctorExamPageActiveStep === 12 ||
		COMPONENT_NAME_LIST.filter(cName => !!state.ui.isComponentEditing[cName])
			.length > 0
	)
}

export const selectGlobalEditingStatus = (state: RootState) => {
	return (
		state.ui.doctorExamPageActiveStep !== 12 &&
		Object.values(state.ui.isComponentEditing || {}).some(value => value)
	)
}

const _selectShowCallWidget = (state: RootState) => state.ui.showCallWidget

export const selectShowCallWidget = createSelector(
	selectIsDoctor,
	_selectShowCallWidget,
	(isDoctor, showCallWidget) => !isDoctor || showCallWidget,
)

export const selectOverrideCallWidget = (state: RootState) =>
	state.ui.overrideCallWidget

export const selectIsCallActive = (exam: string) => (state: RootState) =>
	state.ui.currentCalling === exam

export const selectCurrentChatInfo = (state: RootState) =>
	state.ui.currentChatInfo

export const selectControlPanelActiveStep = (state: RootState) =>
	state.ui.controlPanelActiveStep

export const selectControlPanelActiveSubStep = (state: RootState) =>
	state.ui.controlPanelActiveSubStep

export const selectClientAppOpened = (state: RootState) =>
	state.ui.clientAppOpened

export const selectConsultationSelectedStep = (state: RootState) =>
	state.ui.consultationSelectedStep

export const selectSummaryActiveTab = (state: RootState) =>
	state.ui.summaryActiveTab

export const selectIsConsultationPrescriptionPage = (state: RootState) => {
	return state.ui.doctorExamPageActiveStep === ExamStep.Consultation
}

export const selectPrescriptionAssets = () => (state: RootState) =>
	state.ui.prescriptionAssets ? state.ui.prescriptionAssets.glasses : null

export const selectDiseaseAndTreatmentsAssets = () => (state: RootState) =>
	state.ui.diseaseAndTreatmentAsset ? state.ui.diseaseAndTreatmentAsset : null

export const selectSelectedPrescriptionAsset = (state: RootState) =>
	state.ui.selectedPrescriptionAsset

export const selectOpenPrimaryChiefCompCategory = (state: RootState) =>
	state.ui.openPrimaryChiefCompCategory

export const selectShowDoctorExamViewFooter = (state: RootState) =>
	state.ui.showDoctorExamViewFooter

export const selectPretestConditionShowDialog = (state: RootState) =>
	state.ui.pretestExamCondition.showDialog

export const selectPretestConditionReplaceValue = (state: RootState) =>
	state.ui.pretestExamCondition.replaceValue

export const selectPretestConditionShouldReplaceValues = (state: RootState) =>
	state.ui.pretestExamCondition.shouldReplaceValues

export const selectPretestConditionShouldReplaceValuesFromCondition = (
	state: RootState,
) => state.ui.pretestExamCondition.shouldReplaceValuesFromCondition

export const selectRefractionConditionShowDialog = (state: RootState) =>
	state.ui.refractionExamCondition.showDialog

export const selectRefractionConditionReplaceValue = (state: RootState) =>
	state.ui.refractionExamCondition.replaceValue

export const selectRefractionConditionShouldReplaceValues = (
	state: RootState,
) => state.ui.refractionExamCondition.shouldReplaceValues

export const selectRefractionConditionShouldReplaceValuesFromCondition = (
	state: RootState,
) => state.ui.refractionExamCondition.shouldReplaceValuesFromCondition

export const selectPrivacyWarningDialogState = (state: RootState) =>
	state.ui.hidePrivacyWarningDialog
