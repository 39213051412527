import i18n from 'i18next'

import { CP_DRAWER_TABS, DRAWER_TABS } from '../model/drawer'

import {
	ExamCondition,
	ExamConditionPages,
	ExamConditionPagesMap,
	ExamTestsType,
} from '../model/examCondition'
import { Id } from '../model/model'
import {
	Privilege,
	PrivilegeFormRecord,
	Role,
	StoreCooperation,
	User,
	UserPrivilege,
	UserStorePayload,
} from '../model/users'
import { useMemo } from 'react'

export const operativeRoles: Role[] = [
	'Technician',
	'Refractionist',
	'Doctor',
	'FrontDesk',
]

export const adminRoles: Role[] = ['TechnicalAdmin', 'GlobalAdmin']

export const userRoles: Role[] = [
	'TechnicalAdmin',
	'GlobalAdmin',
	'Technician',
	'Refractionist',
	'Doctor',
	'FrontDesk',
	'Guest',
]

export const isRole = (role: any): role is Role => userRoles.includes(role)

export const inStoreUserRoles: Role[] = [
	'TechnicalAdmin',
	'GlobalAdmin',
	'Technician',
]

export const privilegesEnabledForStores: Privilege[] = [
	UserPrivilege.StoreManager,
	UserPrivilege.SetupManager,
]

export const privilegesEnabledForReport: Privilege[] = [
	UserPrivilege.ReportManager,
]

export const getIsDoctor = (userRole: Role) => userRole === 'Doctor'

export const getIsAdmin = (userRole: Role) => adminRoles.includes(userRole)

export const checkHasInStoreStores = (userRole: Role) => {
	const allowedRoles: Role[] = ['Technician', 'Doctor']
	return allowedRoles.some(role => userRole === role)
}

export const checkHasRemoteStores = (userRole: Role) =>
	userRole === 'Doctor' || userRole === 'Refractionist'

export const joinInStoreStoresAndPrivileges = (
	inStoreStores: Id[],
	privileges: PrivilegeFormRecord,
): UserStorePayload[] => {
	return inStoreStores.map(storeId => ({
		inStore: true,
		remote: false,
		store: storeId,
		privileges: Object.entries(privileges[storeId] || {})
			.filter(([, enabled]) => enabled)
			.map(([name]) => name as Privilege),
	}))
}

export const userPrivileges = (stores: StoreCooperation[]): Privilege[] => {
	const privileges = stores.flatMap(s => s.privileges || [])

	return [...new Set(privileges)]
}

export const userRoleAndPrivileges = (user?: User): (Role | Privilege)[] => {
	const privileges: (Role | Privilege)[] = []
	if (!user) return privileges

	privileges.push(user.role)
	return privileges.concat(userPrivileges(user.stores))
}

export const azureCommunicationReceiverRoles: Role[] = ['Technician']

export const getControlPanelTabs = (
	privileges: (Privilege | Role)[],
	isPracticeManager: boolean,
	withLabel?: boolean,
) =>
	CP_DRAWER_TABS.filter(t => {
		const practiceManagerTabs: DRAWER_TABS[] = [
			'practice',
			'users',
			'missionControl',
			'reports',
			'stores',
			'panels',
		]

		return (
			t.enabledUser.some(r => privileges.includes(r)) ||
			(isPracticeManager && practiceManagerTabs.includes(t.tab))
		)
	}).map((i, index) => {
		return withLabel
			? {
					...i,
					label: i18n.t(i.label),
					stepId: index,
					...(i.subItems
						? {
								subItems: i.subItems.map(s => ({
									...s,
									label: i18n.t(s.label),
									pageTitle: i18n.t(s.pageTitle),
								})),
						  }
						: {}),
			  }
			: i.tab
	})

export const getPracticeTabs = (isPracticeDefine: boolean) => {
	const steps = [
		{
			stepId: 'generalSettings',
			label: i18n.t('legalEntity.generalSettings'),
		},
	]

	if (isPracticeDefine) {
		steps.push({
			stepId: 'orderConfiguration',
			label: i18n.t('legalEntity.orderConfiguration'),
		})
		steps.push({
			stepId: 'reports',
			label: i18n.t('admin.reportTemplates.title'),
		})
		steps.push({
			stepId: 'alertsConfiguration',
			label: i18n.t('admin.alertCategory.tabs.practiceTitle'),
		})
		steps.push({
			stepId: 'tasksConfiguration',
			label: i18n.t('admin.tasks.tabs.practiceTitle'),
		})
		steps.push({
			stepId: 'surgeryConfiguration',
			label: i18n.t('legalEntity.surgeryConfiguration.title'),
		})
		steps.push({
			stepId: 'visDocumentsConfiguration',
			label: i18n.t('admin.visDocuments.tabs.title'),
		})
	}

	return steps
}

const isConditionPresentInPage = (
	page: ExamConditionPages,
	condition: ExamCondition,
) => {
	const pageFeatures = ExamConditionPagesMap[page]
	const conditionFeatures = [...Object.keys(condition.sections)]

	return conditionFeatures.some(F => pageFeatures.includes(F as ExamTestsType))
}

export const useUserConditions = (
	page: ExamConditionPages,
	user?: User,
	leConditions: ExamCondition[] = [],
) => {
	const userConds = useMemo(() => {
		return user?.conditions || []
	}, [user?.conditions])

	const leConds = useMemo(() => {
		return leConditions.filter(
			i =>
				i.enabled &&
				(user?.disabledLEConditions || [])?.findIndex(d => d === i._id) < 0,
		)
	}, [leConditions, user?.disabledLEConditions])

	const userAndPracticeConditions = [...userConds, ...leConds]

	return userAndPracticeConditions.filter((currentCondition: ExamCondition) =>
		isConditionPresentInPage(page, currentCondition),
	)
}
