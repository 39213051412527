import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ExportStatus } from '../../model/export'

export type PatientExportState = {
	exportState: ExportState[]
	patientFullName: string
}

export type ExportState = {
	patientIds: string[]
	status: ExportStatus
	triggeredBy: string
	_id: string
}

const initialState: PatientExportState = {
	exportState: [],
	patientFullName: '',
}

export const patientExportSlice = createSlice({
	name: 'patientExport',
	initialState,
	reducers: {
		setExportState: (state, action: PayloadAction<ExportState[]>) => {
			state.exportState = action.payload
		},
		removeExportStateById: (state, action: PayloadAction<string>) => {
			state.exportState = state.exportState.filter(
				state => state._id !== action.payload,
			)
		},
		addExportState: (state, action: PayloadAction<ExportState>) => {
			state.exportState = [...state.exportState, action.payload]
		},
		setPatientFullName: (state, action: PayloadAction<string>) => {
			state.patientFullName = action.payload
		},
	},
})

export const patientExport = patientExportSlice.reducer
