import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ExportsState } from '../../model/model'
import { ExportDocument } from '../../model/export'

const initialState: ExportsState = []

export const exportsSlice = createSlice({
	name: 'exports',
	initialState,
	reducers: {
		_loadExports: (_, { payload }: PayloadAction<ExportDocument[]>) => payload,
		setDownload: (state, { payload }: PayloadAction<string>) => {
			const item = state.find(item => item._id === payload)
			if (item) {
				item.downloaded = true
			}
		},
	},
})

export default exportsSlice.reducer
