import { SnackbarOrigin } from '@mui/material/Snackbar'

import { DifFormValues } from '../components/DigitalIntakeForm/types'
import { CDAModel } from '../components/ExamSummary/ExamSummaryCDA/model'
import { MFEState } from '../features/mfe/mfeSlice'
import queue from '../features/queue/slice'
import { slice as sesSlice } from '../features/ses/slice'
import { fieldsDataApi } from '../services/fieldsData'
import { fieldsDataMetaApi } from '../services/fieldsDataMeta'
import { ActiveAlert, AlertCategory } from './alerts'
import {
	AppointmentPrivacyPolicyDocumentSigned,
	CheckInData,
	LockId,
	PrivacyPolicyDocumentGuarantor,
	ScheduleId,
	TimeSlotData,
} from './appointment'
import { AzureCommunicationData } from './azureCommunication'
import {
	CarouselData,
	ClinicalDataCarousel,
	DiseaseAndTreatmentsDataCarousel,
	MyopiaCarouselContent,
	PrescriptionCLCarousel,
	PrescriptionDataCarousel,
	TotCarouselContent,
} from './carousel'
import { CDS } from './cds'
import { ChatState } from './chat'
import { ChatbotState } from './chatbot'
import { ClipboardIncomingData } from './clipboard'
import { CockpitContent, CockpitState } from './cockpit'
import { Code } from './coding'
import { Contact } from './contact'
import { ConnectCoreMediaContent } from './contentCoreMedia'
import { Country } from './country'
import { SystemCodeCPT } from './cpt'
import { DiagnosiPlan } from './diagnosiPlans'
import { Dialog } from './dialog'
import {
	ContactLensesCatalogData,
	DiagnosisCatalogData,
	DigitalIntakeForm,
	Exam,
	ExamExtra,
	HealthDescriptionsCatalogData,
	StrippedExam,
} from './exam'
import { ExamCondition } from './examCondition'
import { ICD10 } from './icd10'
import { InstrumentInRoom } from './instruments'
import { SystemCodeLoinc } from './loinc'
import { MedicalReportTemplate } from './medicalReportTemplates'
import { OcularHealthConditionState } from './ocularHealthCondition'
import { PanelsState } from './panel'
import {
	DemographicData,
	InternalPatientStore,
	Patient,
	PatientAlert,
	SeverityKeys,
} from './patient'
import { PatientMatch } from './patientMatch'
import { PracticesState } from './practice'
import {
	PatientSignatureSource,
	Prescription,
	PrescriptionData,
} from './prescription'
import {
	ReferralDoctor,
	ReferralReasonsCatalogData,
	ReferralSpecialtiesCatalogData,
} from './referralDoctors'
import { RenewalState } from './renewal'
import { Report } from './report'
import { SearchState } from './search'
import { SelectedUsers } from './selectedUsers'
import { SignRequestsState } from './signRequests'
import { SnomedCT } from './snomedCT'
import { State } from './state'
import {
	CommunicationProviderTypes,
	RoomSelectionPage,
	StoresState,
} from './store'
import { Task } from './task'
import { Timezone } from './timezone'
import { TroubleShooting } from './troubleShooting'
import { Role, User } from './users'
import { VAScaleConversion } from './vaScales'
import { VisualSimulationState } from './visualSimulation'
import { TimeoutConfigWithId } from './timeoutConfig'
import { TeloAlertActions } from '../styleguide/Alert'
import { PatientExportState } from '../features/patientExport/slice'
import { ExportDocument } from './export'

export type Id = string
export type Time = number

export interface Address {
	street: string
	number: string
	state: string
	province: string
	zip: string
}

export type Seconds = number

export type LoginStatus =
	| 'not logged in'
	| 'logging in'
	| 'logged in'
	| 'logging out'

export type MediaGrants = 'granted' | 'denied' | 'checking' | 'unknown'

export type Patients = { [id: string]: Patient }

export interface AuthState {
	token: string
	expirationDate: string
	role: Role
	privileges?: string[]
	loginStatus: LoginStatus
	eclipsUserVerified: boolean
	authenticationError: boolean
	missingUserError: string
}

export type MfeLoginData = Omit<AuthState, 'loginStatus'>

export interface LoginToken {
	im_token: {
		access_token: string
		refresh_token: string
		expires_in: number
	}
	username: string
	role: string
	state?: string
	exp: number
}

export interface AppState {
	inStore: boolean
	isAppGuideActive: boolean
	lang: string
	mediaGranted: MediaGrants
	newCropStatusCount: number
	online: boolean
	ottIndex: number
	ottMode: boolean
	ottStepsSelected: number[]
	practiceExternalId: string
	roomDeviceAvailable?: boolean | undefined
	runOTT: boolean
	sessionId: string
	storeId: string
	queueAvailable: boolean
	isDebugMode: boolean
}

export type ErrorOrigin =
	| 'internal-api'
	| 'internal-ui'
	| 'external'
	| 'WCS'
	| 'unknown' // TODO: add types as needed

export type HttpErrorCode = 400 | 401 | 403 | 404 | 405 | 408 | 500

export interface HttpError {
	status: HttpErrorCode
	stack: string
	message: string
	internalUrl: string
	externalUrl: string
	origin: ErrorOrigin
	nonBlockingErrorKey?: string
}

export interface RecordedHttpError extends HttpError {
	id: string
}

export interface UiError {
	id: string
	message: string
	stack: string
}

export interface RecordedUiError extends UiError {
	id: string
}

export interface MissingAuthError {
	id: string
	route: string
}

export interface LoginError {
	message: string
	details: string
}

export interface InvalidValuesError {
	id: string
	value: boolean
}

export interface Errors {
	http: RecordedHttpError[]
	ui: RecordedUiError[]
	missingAuth: MissingAuthError[]
	login: LoginError
	invalidValues: InvalidValuesError[]
	resetErrors: boolean
}

export interface AppointmentState {
	today: CheckInData[]
	availableSlots: TimeSlotData[]
	lockedSlotId: LockId
	scheduleId: ScheduleId
	digitalIntakaForm?: { [id: string]: DigitalIntakeForm }
	forceRefresh: boolean
}

type Exams = { [id: string]: Exam | StrippedExam }

export interface CurrentDisplayedExam {
	currentDisplayedExam?: Exam | StrippedExam
}

export type ExamsWithCurrentDisplayedExam = (Exam | StrippedExam)[] &
	CurrentDisplayedExam

export type ExamsState = Exams & CurrentDisplayedExam

export type NotificationType = 'success' | 'error' | 'info'

export enum NotificationSeverity {
	Success = 'success',
	Info = 'info',
	Error = 'error',
	Neutral = 'neutral',
	Warning = 'warning',
}

export type StandardNotification = {
	type: NotificationType
	severity: never
	isIconVisible: never
	anchorOrigin: never
	isAlert?: false
}

export type AlertNotification = {
	isAlert: true
	severity: NotificationSeverity
	isIconVisible?: boolean
	anchorOrigin?: SnackbarOrigin
}

export type Notification = (StandardNotification | AlertNotification) & {
	_id: Id
	title?: string
	message?: string
	autoClose: boolean
	errorId?: string
	errorType?: 'ui' | 'http'
	messageIsLabelKey?: boolean
	titleIsLabelKey?: boolean
	autoCloseDelay?: number
	actions?: TeloAlertActions

	// Optional parameters to support
	// notifications with sound and colored ui
	colored?: boolean
	playSound?: boolean
	soundOnly?: boolean
}

export type AzureStatusIndicator = {
	show: boolean
	active: boolean
	error: boolean
}

export type AzureCallee = {
	username: string
	online: boolean
}

export interface AzureCommunicationState {
	data: AzureCommunicationData
	loginStatus: LoginStatus
	teloCallStatus: 'NONE' | 'STARTED' | 'ENDED' | 'FORCE_ENDED'
	callee: AzureCallee
}

export interface ErrorToLog
	extends Omit<HttpError, 'id' | 'status'>,
		Omit<UiError, 'id'> {}

export type Language = 'en-US' | 'sp-US'

export interface AppConfig {
	app: {
		name: string
		version: string
	}
	apiUrl: string
	apiUrlV2: string
	apiUrlV3: string
	socketUrl: string
	languages: Language[]
	env: string
	logout: string
	mfe: boolean
	environment: string
	login: {
		clientId: string
		redirectUri: string
		redirectUriEclips: string
		url: string
		bypassLogin: boolean
		fakeUserRole: string
	}
	digitalIntakeForm: {
		url: string
		subscriptionKey: string
	}
	newCrop: {
		url: string
	}
	region: string
	featuresSwitch: {
		ai: boolean
		dif: boolean
		codesToBill: boolean
		patientPortalSignature: boolean
	}
	ui: {
		muiPro: string
	}
	mips: {
		url: string
	}
	translations: {
		project: string
		url: string
	}
	fhirSmartApp: { url: string }
}

export interface LoginResponse {
	token: string
}

export interface NoDialogType {
	type: 'none'
}

export interface LogoutDialogType {
	type: 'logout'
}

export interface UncompletedPrescriptionDialogType {
	type: 'uncompletedPrescription'
}

export interface UnlockDialogType {
	type: 'unlockDialog'
	examId: Id
	storeId: string
}

export interface AbortDialogType {
	type: 'abort'
	examId: Id
}

export interface QuitWithTrialType {
	type: 'quitWithTrial'
	examId: Id
}

export interface Prescriptions {
	[id: string]: Prescription
}

export interface PrescriptionsState {
	list: Prescriptions
	inShare?: string
	isDirty?: boolean
	hasErrors?: boolean
}

export type PrescriptionState = {
	updatePrescriptionNeeded: boolean
	loadingPrescription: boolean
	contactLensesPresent: boolean | undefined
	patientSignatureSource?: PatientSignatureSource
	isPrescriptionEmpty: boolean | undefined
	invalidExpiration: boolean | undefined
}

export type UsersState = {
	[userName: string]: User
}

export type SelectedUsersState = SelectedUsers

export type ContactsState = {
	contacts: Contact[]
}

export const COMPONENT_NAME_LIST = [
	'AssessmentPlan',
	'Autorefraction',
	'Auxiliary',
	'BinocularAssessment',
	'BloodPressure',
	'ColorVision',
	'EyeHealth',
	'finalRefraction',
	'LensesTrial',
	'Lensometry',
	'HistoricalRX',
	'Nct',
	'OcularHealth',
	'PatientMasterData',
	'PupilsTesting',
	'ReferralDoctor',
	'RetinalImaging',
	'RetinoscopyDry',
	'RetinoscopyWet',
	'Stereo',
	'VideoSlitLamp',
	'VisualAcuity',
	'VisualFields',
	'cycloplegicRefraction',
	'subjectiveRefraction',
	'OCT',
	'VergeancesHorizontal',
	'VergeancesVertical',
	'PursuitsSaccades',
	'AnteriorSegment',
	'VitalsData',
	'OrderResults',
] as const

export type ComponentName = (typeof COMPONENT_NAME_LIST)[number]
export type ComponentNameElement = typeof COMPONENT_NAME_LIST

export type TestValues = Record<ComponentName, any>

export type UIState = {
	isComponentEditing: Partial<Record<ComponentName, boolean>>
	doctorExamPageActiveStep: number
	doctorExamPageActivePanel?: InstrumentInRoom
	controlPanelActiveStep: number
	controlPanelActiveSubStep: number
	showCallWidget: boolean
	clientAppOpened: boolean
	overrideCallWidget: CommunicationProviderTypes | null
	currentCalling?: string
	currentChatInfo?: {
		examId: Id
		internalPatientId: Id
	}
	consultationSelectedStep: number
	summaryActiveTab: number
	prescriptionAssets?: { glasses?: CockpitContent }
	diseaseAndTreatmentAsset?: CockpitContent
	selectedPrescriptionAsset?: string
	sendNewRequest?: boolean
	sendDoctorStarted?: boolean
	openPrimaryChiefCompCategory: boolean
	showDoctorExamViewFooter: boolean
	notistackKeysTriggers: {
		[k: string]: string
	}
	pretestExamCondition: {
		showDialog: boolean
		replaceValue: string
		shouldReplaceValues: boolean
		shouldReplaceValuesFromCondition: boolean
	}
	refractionExamCondition: {
		showDialog: boolean
		replaceValue: string
		shouldReplaceValues: boolean
		shouldReplaceValuesFromCondition: boolean
	}
	hidePrivacyWarningDialog: boolean
}

export type ContactLensesState = {
	catalog: ContactLensesCatalogData[]
	sameProductBothEyesEnabledByTab: { [key: number]: boolean }
}

export type DiagnosisState = {
	catalog: DiagnosisCatalogData[]
}

export type DiagnosiPlansState = {
	catalog: DiagnosiPlan[]
}

export type MedicalReportTemplatesState = {
	catalog: MedicalReportTemplate[]
}

export type HealthDescriptionsState = {
	catalog: HealthDescriptionsCatalogData[]
}

export type ReferralCommonsState = {
	reasons: ReferralReasonsCatalogData[]
	specialties: ReferralSpecialtiesCatalogData[]
}

export type StatesState = {
	states: State[]
}

export type CountriesState = {
	countries: Country[]
}

export type TimezonesState = {
	timezones: Timezone[]
}

export type ClipboardState = ClipboardIncomingData | null

export type ReferralDoctorsState = {
	[id: string]: ReferralDoctor[]
}

export type CarouselState = {
	showCarousel: boolean
	shareCarousel: boolean
	slidesQuantity: number
	activeSlide: number
	clinicalDataContent: ClinicalDataCarousel
	carouselData?: CarouselData
	prescriptionContent?: PrescriptionDataCarousel[]
	diseaseTreatmentContent?: DiseaseAndTreatmentsDataCarousel[]
	prescriptionForm?: PrescriptionData
	prescriptionClContent?: PrescriptionCLCarousel[]
	myopiaContent: MyopiaCarouselContent
	trendsOverTimeContent: TotCarouselContent
}

export interface SignedDocuments {
	[key: Id]: AppointmentPrivacyPolicyDocumentSigned[]
}

export interface PatientGuarantor {
	[patientId: Id]: PrivacyPolicyDocumentGuarantor
}

export interface SignDocumentsState {
	signedDocuments: SignedDocuments
	patientGuarantor: PatientGuarantor
}

export type WorklistFiltersType = {
	dateFrom: string
	dateRange: boolean
	onlyRemote?: boolean
	technician: string[]
	chartStatus: string
	dateTo: string | null
	icd10: ICD10[]
	loinc: SystemCodeLoinc[]
	cpt: SystemCodeCPT[]
	snomed: SnomedCT[]
	status: string[]
	patientSignatureStatus: string
}

export type WorklistState = {
	worklistFilters: WorklistFiltersType
	currentPaginationPage: number
	paginationTotalPages: number
	availableToggleWasClicked: boolean
	showAvailableToggleLoader: boolean
}

export type DifState = Record<string, DifFormValues>

export interface RootState {
	alerts: AlertsState
	app: AppState
	appointments: AppointmentState
	signDocuments: SignDocumentsState
	auth: AuthState
	azureCommunication: AzureCommunicationState
	carousel: CarouselState
	chat: ChatState
	chatbot: ChatbotState
	clipboard: ClipboardState
	cockpit: CockpitState
	contactLenses: ContactLensesState
	coremedia: ConnectCoreMediaContent
	countries: CountriesState
	diagnosiPlans: DiagnosiPlansState
	diagnosis: DiagnosisState
	difs: DifState
	dialog: Dialog
	errors: Errors
	examConditions: ExamConditionState
	exams: ExamsState
	exports: ExportsState
	healthDescriptions: HealthDescriptionsState
	medicalReportTemplates: MedicalReportTemplatesState
	notifications: Notification[]
	ocularHealthConditions: OcularHealthConditionState
	panels: PanelsState
	patientMatch: PatientMatch
	patients: InternalPatientStore
	practices: PracticesState
	prescriptions: PrescriptionsState
	prescription: PrescriptionState
	referralCommons: ReferralCommonsState
	referralDoctors: ReferralDoctorsState
	renewal: RenewalState
	reports: ReportsState
	reportFilters: ReportFilters
	roomSelectionPages: RoomSelectionPage
	selectedUsers: SelectedUsersState
	ses: ReturnType<typeof sesSlice.reducer>
	search: SearchState
	signRequests: SignRequestsState
	states: StatesState
	stores: StoresState // at least admin can see multiple stores
	tasks: TasksState
	timeouts: TimeoutsState
	timezones: TimezonesState
	troubleShooting: TroubleShooting
	ui: UIState
	users: UsersState
	contacts: ContactsState
	vaScales: VaScaleState
	visualSimulation: VisualSimulationState
	fieldsDataApi: ReturnType<typeof fieldsDataApi.reducer>
	fieldsDataMetaApi: ReturnType<typeof fieldsDataMetaApi.reducer>
	worklist: WorklistState
	mfe: MFEState
	coding: CodingState
	refractionClassicExam: RefractionClassicExamState
	queue: ReturnType<typeof queue>
	orders: OrdersState
	patientExport: PatientExportState
}

export enum TypePhoneNumber {
	HOME = 'Home',
	OFFICE = 'Work',
	PERSONAL = 'Cell',
}

export enum Languages {
	ENGLISH = 'en',
	SPANISH = 'es',
	FRENCH = 'fr',
	OTHER = 'other',
}

export interface FirstDataModel {
	id?: string
	name: string
	email: string
	language?: string
	middleName?: string
	surname: string
	birthDate: Date
	phoneNumber: string
	typePhoneNumber: TypePhoneNumber | ''
	secondaryPhone?: string
	secondaryPhoneType?: TypePhoneNumber | ''
	address?: string
	postalCode?: string
	country?: string
	city?: string
}

export interface TimeSlotView {
	value: string
	label: string
}

export interface DateStepModel {
	appointmentDate: Date
	timeSlot: TimeSlotView[]
}

export interface DemographicDataExtra extends DemographicData, ExamExtra {}

export type Base64Image = string

export type ListItemView = {
	label: string
	value: string | null
}

export type OptionItem = {
	label: string
	value: string
}

export type BooleanObjectType = {
	[key: string]: boolean
}

export type DeleteAppointmentDialogType = {
	type: 'deleteAppointment'
}

export type RefractionBoxType =
	| 'finalRefraction'
	| 'subjectiveRefraction'
	| 'cycloplegicRefraction'

export type BinocularAssessmentBagType =
	| 'fullBinocularAssessment'
	| 'pupilTesting'

export type SensitivityPdType = 'sensitivity' | 'pupillaryDistance'

export type SaveMultipleFormBagID = 'examFlow.refractionTab' | ''

export type ExternalSource = {
	code: string
	source: string
}

export type VaScaleState = {
	scales: VAScaleConversion[]
}

export type RefractionClassicExamState = {
	rerender: 0 | 1
}

export type ReportsState = Report[]

export type ReportFilters = {
	isLEManager?: boolean
	userName?: string
	name?: string
	type?: string
	selectedStores?: any
	storeIds?: string[]
	dateFrom?: string
	dateTo?: string
	page?: number
	pages?: number
	total?: number
}

export type DateIsoString = string

export type ActiveAlerts = {
	[key in SeverityKeys]: ActiveAlert
}

export type YyyyMmDm = string

export type AlertsState = {
	existingCategoryNames: string[]
	list: PatientAlert[]
	availableCategories: AlertCategory[]
	activeAlerts: ActiveAlerts
	rules: CDS[]
	uprise: PatientAlert[]
	cds: PatientAlert[]
	prevOpener: string
	alertsPageIsOpened: boolean
}

export type TasksState = Task[]

export type Creator = {
	name: string
	role?: string
	privilege?: string
}

export type CodingState = Code[]

export type CDAResponse = CDAModel[]

export type ExamConditionState = ExamCondition[]

export type OrdersState = {
	selectedTests: string[]
	testToDelete: string[]
	testsValues: TestValues
	initialTestsValues: TestValues
	errors: boolean
	orderResultNavigation?: {
		orderId: string
		resultId: string
	}
}

export type TimeoutsState = TimeoutConfigWithId

export type ExportsState = ExportDocument[]
