import { Privilege, Role } from './users'

export type DRAWER_TABS =
	| 'config'
	| 'missionControl'
	| 'users'
	| 'stores'
	| 'panels'
	| 'practice'
	| 'reports'
	| 'myPanels'

type DRAWER_TABS_SUB_KEY = 'report' | 'export'

type MenuItem = {
	id: DRAWER_TABS_SUB_KEY
	label: string
	pageTitle: string
	stepId: number
	disabled?: boolean
	hide?: boolean
}

export type DRAWER_TAB = {
	tab: DRAWER_TABS
	label: string
	ref: string
	enabledUser: (Role | Privilege)[]
	subItems?: MenuItem[]
}

export const CP_DRAWER_TABS: DRAWER_TAB[] = [
	{
		tab: 'config',
		label: 'admin.generalConfig',
		ref: '/admin',
		enabledUser: [
			'TechnicalAdmin',
			'GlobalAdmin',
			'Doctor',
			'Technician',
			'FrontDesk',
		],
	},
	{
		tab: 'missionControl',
		label: 'admin.missionControl',
		ref: '/admin',
		enabledUser: [
			'TechnicalAdmin',
			'GlobalAdmin',
			'FrontDesk',
			'LegalEntityManager',
		],
	},
	{
		tab: 'users',
		label: 'admin.users',
		ref: '/admin',
		enabledUser: ['TechnicalAdmin', 'GlobalAdmin', 'FrontDesk'],
	},
	{
		tab: 'stores',
		label: 'admin.store',
		ref: '/admin',
		enabledUser: [
			'TechnicalAdmin',
			'GlobalAdmin',
			'StoreManager',
			'SetupManager',
			'FrontDesk',
			'LegalEntityManager',
		],
	},
	{
		tab: 'practice',
		label: 'admin.practice',
		ref: '/admin',
		enabledUser: ['TechnicalAdmin', 'GlobalAdmin', 'FrontDesk'],
	},
	{
		tab: 'panels',
		label: 'admin.panel',
		ref: '/admin',
		enabledUser: ['GlobalAdmin', 'FrontDesk', 'LegalEntityManager'],
	},
	{
		tab: 'myPanels',
		label: 'admin.myPanel',
		ref: '/admin',
		enabledUser: ['Doctor'],
	},
	{
		tab: 'reports',
		label: 'admin.exportExams',
		ref: '/admin',
		enabledUser: [
			'TechnicalAdmin',
			'GlobalAdmin',
			'ReportManager',
			'FrontDesk',
			'LegalEntityManager',
		],
		subItems: [
			{
				id: 'report' as DRAWER_TABS_SUB_KEY,
				label: 'admin.reportPage',
				pageTitle: 'admin.reportPageTitle',
				stepId: 0,
			},
			{
				id: 'export' as DRAWER_TABS_SUB_KEY,
				label: 'admin.exportPage',
				pageTitle: 'admin.exportPage',
				stepId: 1,
			},
		],
	},
]
