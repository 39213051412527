import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit'

import i18n from 'i18next'

import notificationsActions from '../features/notifications/actions'
import store from '../store'
import { ServicesEndpointName } from './constants'
import { ErrorCode } from '../model/errors'

export const rtkQueryErrorLogger: Middleware = () => next => action => {
	// RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
	if (isRejectedWithValue(action)) {
		if (
			action.meta.arg.endpointName !== ServicesEndpointName.CheckInactivityLogin
		) {
			if (action.payload.data.errorCode === ErrorCode.EXPORT_NO_EXAMS) {
				store.dispatch<any>(
					notificationsActions.addNotification({
						type: 'error',
						message: i18n.t(`errors.${ErrorCode.EXPORT_NO_EXAMS}`),
						autoClose: true,
					}),
				)
			} else {
				store.dispatch<any>(
					notificationsActions.addNotification({
						type: 'error',
						message: `Error calling the ${action.type.split('/')[0]}, ${
							action.meta.arg.endpointName
						} method`,
						autoClose: true,
						errorType: 'http',
						messageIsLabelKey: true,
					}),
				)
			}
		}
	}
	return next(action)
}
