import { useCallback, useEffect } from 'react'

import { fetchNewCropTasks } from '../components/Prescription/NewCrop/api'
import appActions from '../features/app/actions'
import { selectIsOTTMode, selectStoreId } from '../features/app/selectors'
import { selectUsername } from '../features/auth/selectors'
import azureCommunicationActions from '../features/azureCommunication/actions'
import {
	connectToSocketKeepAlive,
	disconnectFromSocketKeepAlive,
} from '../features/socket/keepAlive/keepAliveSocket'
import {
	connectToSocketUserOnlineStatus,
	disconnectFromSocketUserOnlineStatus,
} from '../features/socket/userOnlineStatus/userOnlineStatusSocket'
import { selectStoreByStoreId } from '../features/stores/selectors'
import { FIFTEEN_SECONDS, FIVE_MINUTES } from '../libs/time'
import { InstrumentType } from '../model/instrumentsApi'
import { Id } from '../model/model'
import { Platform } from '../model/users'
import { useTeloRouter } from '../routing/teloRouter'
import { useTeloDispatch, useTeloSelector } from '../store'
import {
	getFetchAssessments,
	getFetchExam,
	getFetchExports,
	getFetchInstrumentData,
	getFetchInstrumentsInRoom,
	getFetchReports,
	getFetchStages,
	getFetchUser,
} from './throtteledFetchActions'
import { useGetQuery } from '../services/export'

export const usePollingExamById = (examId?: Id) => {
	const dispatch = useTeloDispatch()

	useEffect(() => {
		if (!examId) {
			return
		}

		const fetchExams = getFetchExam(examId, true, dispatch)

		fetchExams()
	}, [dispatch, examId])
}

export const usePollingUser = (username: string) => {
	const dispatch = useTeloDispatch()
	const { navigate } = useTeloRouter()
	useEffect(() => {
		const fetchUser = getFetchUser(username, dispatch, navigate)
		fetchUser()

		let fetchInterval: number | undefined
		fetchInterval = window.setInterval(fetchUser, FIVE_MINUTES)

		return function cleanUp() {
			fetchInterval && clearInterval(fetchInterval)
		}
	}, [dispatch, navigate, username])
}

export const usePollingAssessments = (disableFetchAssessments: boolean) => {
	const dispatch = useTeloDispatch()
	useEffect(() => {
		if (disableFetchAssessments === true) {
			return
		}
		const fetchAssessments = getFetchAssessments(dispatch)

		fetchAssessments()

		let fetchInterval: number | undefined
		fetchInterval = window.setInterval(fetchAssessments, FIVE_MINUTES)

		return function cleanUp() {
			fetchInterval && clearInterval(fetchInterval)
		}
	}, [dispatch, disableFetchAssessments])
}

export const useGetInstrumentData = (
	examId: Id,
	examLoaded: boolean,
	autoUpdate: boolean,
	instrumentType: InstrumentType,
) => {
	const dispatch = useTeloDispatch()
	const isOttMode = useTeloSelector(selectIsOTTMode)
	useEffect(() => {
		if (isOttMode) {
			return
		}

		const fetchInstrumentData = getFetchInstrumentData(
			examId,
			instrumentType,
			dispatch,
		)

		if (examLoaded && examId) {
			fetchInstrumentData()
		}

		let intervalId: number | undefined

		if (autoUpdate && examLoaded && examId && intervalId === undefined) {
			intervalId = window.setInterval(fetchInstrumentData, FIVE_MINUTES)
		}
		return function cleanUp() {
			intervalId && clearInterval(intervalId)
		}
	}, [dispatch, examId, examLoaded, autoUpdate, instrumentType, isOttMode])
}

export const usePollingStages = () => {
	const dispatch = useTeloDispatch()
	const storeId = useTeloSelector(selectStoreId)
	const store = useTeloSelector(selectStoreByStoreId(storeId))
	const storeLoaded = !!store
	useEffect(() => {
		let intervalId: undefined | number

		if (storeId && storeLoaded) {
			const fetchStages = getFetchStages(storeId, dispatch)
			fetchStages()
			intervalId = window.setInterval(fetchStages, FIVE_MINUTES)
		}

		return function cleanUp() {
			intervalId && clearInterval(intervalId)
		}
	}, [dispatch, storeId, storeLoaded])
}

export const usePollingInstrumentsInRoom = (
	storeId: string,
	stageId: Id,
	roomId: Id,
) => {
	const dispatch = useTeloDispatch()

	useEffect(() => {
		const updateInstrumentsInRoom = getFetchInstrumentsInRoom(
			storeId,
			stageId,
			roomId,
			dispatch,
		)

		updateInstrumentsInRoom()

		const intervalId = setInterval(updateInstrumentsInRoom, FIVE_MINUTES)

		return function cleanUp() {
			intervalId && clearInterval(intervalId)
		}
	}, [dispatch, storeId, stageId, roomId])
}

export const useKeepAliveUser = () => {
	const username = useTeloSelector(selectUsername)

	useEffect(() => {
		connectToSocketKeepAlive({ username })
		return () => {
			disconnectFromSocketKeepAlive()
		}
	}, [username])
}

export const useCheckUserOnline = (platform: Platform, username: string) => {
	const dispatch = useTeloDispatch()

	const onUserOnlineStatusChange = useCallback(
		(online: boolean) => {
			dispatch(
				azureCommunicationActions.setCalleeOnlineStatus({ username, online }),
			)
		},
		[dispatch, username],
	)

	useEffect(() => {
		if (!username) {
			return
		}
		connectToSocketUserOnlineStatus({
			username,
			platform: platform === 'teleoptometry' ? 'connect' : 'cockpit',
			onUserOnlineStatusChange,
		})

		return () => {
			disconnectFromSocketUserOnlineStatus()
		}
	}, [dispatch, username, platform, onUserOnlineStatusChange])
}

export const POLLING_TIMER = FIFTEEN_SECONDS

export const usePollingReports = (keepPollingReports: boolean) => {
	const dispatch = useTeloDispatch()
	useEffect(() => {
		if (keepPollingReports) {
			const fetchReports = getFetchReports(dispatch)

			fetchReports()

			let fetchInterval: number | undefined
			fetchInterval = window.setInterval(fetchReports, POLLING_TIMER)

			return function cleanUp() {
				fetchInterval && clearInterval(fetchInterval)
			}
		}
	}, [dispatch, keepPollingReports])
}

export const usePollingExports = (
	keepPollingExports: boolean,
	size: number,
	page: number,
) => {
	const { refetch: refetchExports } = useGetQuery({
		size,
		page,
		type: 'STORES',
	})

	useEffect(() => {
		if (keepPollingExports) {
			const fetchExports = getFetchExports(refetchExports)

			fetchExports()

			let fetchInterval: number | undefined
			fetchInterval = window.setInterval(fetchExports, POLLING_TIMER)

			return function cleanUp() {
				fetchInterval && clearInterval(fetchInterval)
			}
		}
	}, [keepPollingExports, refetchExports])
}

export const useGetNewCropStatusCount = (storeId: string) => {
	const dispatch = useTeloDispatch()

	useEffect(() => {
		if (storeId === undefined || storeId.length === 0) {
			return
		}

		const getNewCropStatusCount = () =>
			fetchNewCropTasks(storeId).then(count => {
				dispatch(appActions.setNewCropStatusCount(count))
			})

		getNewCropStatusCount()

		let fetchInterval: number | undefined
		fetchInterval = window.setInterval(getNewCropStatusCount, FIVE_MINUTES)

		return function cleanUp() {
			fetchInterval && clearInterval(fetchInterval)
		}
	}, [dispatch, storeId])
}
