import { createApi } from '@reduxjs/toolkit/query/react'
import qs from 'qs'

import { staggeredBaseQueryWithBailOut } from '../libs/services'
import { unversionedApiUrl } from './common'
import { ServiceBaseUrl } from './serviceApi'
import { getCommonHeaders } from '../libs/fetchJson'
import {
	ExportCreateBody,
	ExportCreateResponse,
	ExportResponse,
} from '../model/export'

export const exportsApi = createApi({
	reducerPath: 'exportsApi',
	tagTypes: ['exports'],
	baseQuery: staggeredBaseQueryWithBailOut(unversionedApiUrl),
	endpoints: builder => ({
		get: builder.query<
			ExportResponse,
			{
				size?: number
				page?: number
				type?: string
			}
		>({
			query: query => ({
				url: `${ServiceBaseUrl.Exports}/paginated?${qs.stringify(query)}`,
				headers: getCommonHeaders({ addContentType: true }),
			}),
			providesTags: ['exports'],
		}),

		createExports: builder.mutation<ExportCreateResponse, ExportCreateBody>({
			query: body => ({
				url: `${ServiceBaseUrl.Exports}/stores`,
				method: 'POST',
				body: JSON.stringify(body),
				headers: getCommonHeaders({ addContentType: true }),
			}),
			invalidatesTags: ['exports'],
		}),

		deleteExports: builder.mutation<void, string[]>({
			query: ids => ({
				url: `${ServiceBaseUrl.Exports}`,
				method: 'DELETE',
				body: JSON.stringify({ ids }),
			}),
			invalidatesTags: ['exports'],
		}),
	}),
})

export const {
	useGetQuery,
	useCreateExportsMutation,
	useDeleteExportsMutation,
} = exportsApi
