import { AppConfig } from './model/model'

function appendToURL(base: string, toAppend: string): string {
	const separator = base.endsWith('/') ? '' : '/'
	return `${base}${separator}${toAppend}`
}

const safeEnv = (key: string, defaultValue?: string): string => {
	const value: string = process.env[key] || ''
	const result: any = value || defaultValue

	// strict check because empty string must be evaluated as true
	if (result == null) {
		throw new Error(`Missing key in in .env file: ${key}`)
	}
	return result
}

const appConfig: AppConfig = {
	app: {
		name: safeEnv('REACT_APP_NAME', '$REACT_APP_NAME'),
		version: safeEnv('REACT_APP_VERSION', '$REACT_APP_VERSION'),
	},
	apiUrl: appendToURL(safeEnv('REACT_APP_API_URL', '$REACT_APP_API_URL'), 'v1'),
	apiUrlV2: appendToURL(
		safeEnv('REACT_APP_API_URL', '$REACT_APP_API_URL'),
		'v2',
	),
	apiUrlV3: appendToURL(
		safeEnv('REACT_APP_API_URL', '$REACT_APP_API_URL'),
		'v3',
	),
	socketUrl: safeEnv('REACT_APP_SOCKET_URL', '$REACT_APP_SOCKET_URL'),
	env: safeEnv('NODE_ENV', 'development'),
	environment: safeEnv('REACT_APP_ENV', '$REACT_APP_ENV'),
	logout: safeEnv('REACT_APP_LOGOUT_URL', '$REACT_APP_LOGOUT_URL'),
	login: {
		clientId: safeEnv(
			'REACT_APP_LOGIN_CLIENT_ID',
			'$REACT_APP_LOGIN_CLIENT_ID',
		),
		url: safeEnv('REACT_APP_LOGIN_URL', '$REACT_APP_LOGIN_URL'),
		redirectUri: safeEnv(
			'REACT_APP_LOGIN_REDIRECT_URI',
			'$REACT_APP_LOGIN_REDIRECT_URI',
		),
		redirectUriEclips: safeEnv(
			'REACT_APP_LOGIN_REDIRECT_URI_ECLIPS',
			'$REACT_APP_LOGIN_REDIRECT_URI_ECLIPS',
		),
		bypassLogin: safeEnv('REACT_APP_BYPASS_LOGIN', 'false') === 'true',
		fakeUserRole: safeEnv('REACT_APP_FAKE_USER_ROLES', ''),
	},
	languages: ['en-US', 'sp-US'],
	digitalIntakeForm: {
		url: safeEnv('REACT_APP_DIF_URL', '$REACT_DIF_URL'),
		subscriptionKey: safeEnv('REACT_APP_DIF_SUB_KEY', '$REACT_APP_DIF_SUB_KEY'),
	},
	newCrop: {
		url: safeEnv('REACT_APP_NEW_CROP_BASE_URL', '$REACT_APP_NEW_CROP_BASE_URL'),
	},
	mfe: safeEnv('REACT_APP_MFE', 'false') === 'true',
	region: safeEnv('REACT_APP_REGION', '$REACT_APP_REGION'),
	featuresSwitch: {
		ai: safeEnv('REACT_APP_AI_ENABLED', '$REACT_APP_AI_ENABLED') === 'true',
		dif: safeEnv('REACT_APP_DIF_ENABLED', '$REACT_APP_DIF_ENABLED') === 'true',
		codesToBill:
			safeEnv(
				'REACT_APP_CODES_TO_BILL_ENABLED',
				'$REACT_APP_CODES_TO_BILL_ENABLED',
			) === 'true',
		patientPortalSignature:
			safeEnv('REACT_APP_PATIENT_PORTAL_SIGNATURE', 'true') === 'true',
	},
	ui: {
		muiPro: safeEnv('REACT_APP_MUI_X_PRO_LICENSE', ''),
	},
	mips: {
		url: safeEnv('REACT_APP_MIPS_URL', '$REACT_APP_MIPS_URL'),
	},
	translations: {
		project: safeEnv(
			'REACT_APP_TRANSLATION_PROJECT_NAME',
			'$REACT_APP_TRANSLATION_PROJECT_NAME',
		),
		url: safeEnv('REACT_APP_TRANSLATION_URL', '$REACT_APP_TRANSLATION_URL'),
	},
	fhirSmartApp: {
		url: safeEnv(
			'REACT_APP_FHIR_SMART_APP_URL',
			'$REACT_APP_FHIR_SMART_APP_URL',
		),
	},
}

export default appConfig
